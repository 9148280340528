import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setMobileMenuStatus, setLogout } from '@src/redux/actions/layoutActions';
import Head from './head';

const mapStateToProps = ({ layout: { profile, cart, menuOpened } }) => ({
    profile,
    cart,
    menuOpened,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ setMobileMenuStatus, setLogout }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Head);
