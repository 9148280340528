/* eslint-disable react/jsx-props-no-spreading */
import { Row } from 'reactstrap';
import Link from 'next/link';
import Image from 'next/image';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import upperFirst from 'lodash/upperFirst';
import LogoGadgetsFR from '@src/assets/images/logo_gadgets_befr.png';
import LogoGadgetsNL from '@src/assets/images/logo_gadgets_benl.png';
import LogoOfficeFR from '@src/assets/images/logo_office_befr.png';
import LogoOfficeNL from '@src/assets/images/logo_office_benl.png';
import StyledContainer from '@src/components/StyledContainer';
import StyledHamburger from '@src/components/StyledHamburger';
import { useI18n } from '@src/locales';
import { isDev } from '@src/utils';
import * as Styles from './head.styled';

const Search = dynamic(() => import('./Search'), { ssr: true });
const Locales = dynamic(() => import('./Locales'), { ssr: true });

const logos = {
    office: { benl: LogoOfficeNL, befr: LogoOfficeFR },
    gadgets: { benl: LogoGadgetsNL, befr: LogoGadgetsFR },
};

const shop = process.env.NEXT_PUBLIC_SHOP_NAME;

const Head = ({ cart, profile, menuOpened, setLogout, setMobileMenuStatus }) => {
    const { locale } = useRouter();
    const { t } = useI18n();

    /**
     * Log out the session
     */
    function handleClickLogout() {
        setLogout(locale);
    }

    /**
     * Handle menu version mobile
     */
    function handleMobileMenu() {
        setMobileMenuStatus(!menuOpened);
        document.body.style.overflow = 'hidden';
        document.documentElement.style.overflow = 'hidden';
    }

    return (
        <Styles.Head className="head">
            <StyledContainer fluid>
                <Row className="align-items-center justify-content-between">
                    <Styles.Logo xs="3" sm="3" md="3" lg="3" className="logo_flyer pl-0">
                        <Link href={`/${locale}`} passHref>
                            <a>
                                <Image
                                    src={logos[shop][locale]}
                                    layout="intrinsic"
                                    alt={`Flyer.be ${process.env.NEXT_PUBLIC_SHOP_NAME}`}
                                />

                                {isDev !== '' ? <Styles.Dev>{isDev}</Styles.Dev> : null }
                            </a>
                        </Link>
                    </Styles.Logo>

                    <Styles.Search xs="6" sm="4" md="4" lg="4" className="search_bar p-0">
                        <Search />
                    </Styles.Search>

                    <Styles.Profil xs="9" sm="7" md="7" lg="5" className="header_profile pr-0">
                        <Locales />

                        <div>
                            <Styles.Link
                                id="loginlink"
                                href={`${process.env.NEXT_PUBLIC_FLYER}/${locale}/myprintconnect/`}
                                className={cart.loading ? '__loading' : ''}
                                title={profile === null ? '' : upperFirst(profile.firstName)}
                            >
                                <div>
                                    <em><i className="i-user" /></em>
                                    <span>
                                        {profile === null ? t('login') : t('hello', { user: upperFirst(profile.firstName) })}
                                    </span>
                                </div>
                            </Styles.Link>
                            {
                                profile === null ? '' : (
                                    <div className="profil-dropdown">
                                        <nav className="item-list">
                                            <ul>
                                                <li className="first"><Link href={`${process.env.NEXT_PUBLIC_FLYER}/${locale}/${t('myAccount.link')}`}><a>{t('myAccount.text')}</a></Link></li>
                                                <li><Link href={`${process.env.NEXT_PUBLIC_FLYER}/${locale}/${t('myAdresses.link')}`}><a>{t('myAdresses.text')}</a></Link></li>
                                                <li><Link href={`${process.env.NEXT_PUBLIC_FLYER}/${locale}/${t('myOrders.link')}`}><a>{t('myOrders.text')}</a></Link></li>
                                                <li><Link href={`${process.env.NEXT_PUBLIC_FLYER}/${locale}/${t('myBills.link')}`}><a>{t('myBills.text')}</a></Link></li>
                                                <li className="last"><button aria-label={t('signOut')} type="button" onClick={() => handleClickLogout()}>{t('signOut')}</button></li>
                                            </ul>
                                        </nav>
                                    </div>
                                )
                            }
                        </div>

                        <Styles.Link id="cart" href={`${process.env.NEXT_PUBLIC_FLYER}/${locale}/${t('cartLink')}/`}>
                            <div>
                                <em>
                                    <i className="i-cart" />
                                    <Styles.Cart>{cart.count}</Styles.Cart>
                                </em>

                                <span>{t('cart')}</span>
                            </div>
                        </Styles.Link>

                        <Styles.Mobile aria-label="Menu" xs="2" lg="4" type="button" onClick={() => handleMobileMenu()} className={menuOpened ? '__clicked' : ''}>
                            <StyledHamburger>
                                <span />
                            </StyledHamburger>
                        </Styles.Mobile>
                    </Styles.Profil>
                </Row>

                <Styles.MobileSearch>
                    <Search isMobile />
                </Styles.MobileSearch>
            </StyledContainer>
        </Styles.Head>
    );
};

export default Head;
