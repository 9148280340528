import styled from 'styled-components';
import { Col } from 'reactstrap';
import { em, rgba, size, ellipsis } from 'polished';
import StyledHamburger from '@src/components/StyledHamburger';

export const Head = styled.div`
    padding: ${em('8px')} 0;
    margin: 0 0 5px;

    @media (max-width: 991px) {
        padding: 1.25em 0 0;
    }
`;

export const Dev = styled.span`
    position: absolute;
    left: -1em;
    top: 50%;
    margin-top: -10px;
`;

export const Logo = styled(Col)`
    > a {
        position: relative;
        display: inline-block;
    }

    @media (max-width: 991px) {
        padding: 0 1rem;
    }

    @media (max-width: 380px) {
        padding: 0;
    }

    img {
        width: initial;
        max-width: 173px;
        position: relative;
        top: 3px;
    }
`;

export const Search = styled(Col)`
    @media (max-width: 991px) {
        display: none;
    }
`;

export const MobileSearch = styled.div`
    display: none;

    @media (max-width: 991px) {
        display: block;
        margin-top: 1.5em;
    }
`;

export const Profil = styled(Col)`
    text-align: right;
    position: relative;
    top: ${em('9px')};

    @media (max-width: 550px) {
        padding-left: 0;
    }

    > div {
        display: inline-block;
        position: relative;
        padding: 0 0 9px 1em;

        @media (max-width: 360px) {
            padding-left: 0.5em;
        }

        a {
            padding-left: 0;
        }

        .profil-dropdown {
            background: #ffffff;
            width: 207px;
            position: absolute;
            top: 40px;
            left: -98px;
            z-index: 9999;
            border: 1.5px solid #CCD8E2;
            padding: 21px 20px 8px 35px;
            border-radius: 10px;
            box-shadow: 5px 10px 30px 0 rgba(41, 26, 204, 0.12);
            box-sizing: content-box;
            display: none;

            &.isLoading {
                display: none;
            }
 
            ul {
                margin: 0;
                padding: 6px 0 8px;
                width: 100%;

                li {
                    margin: 0;
                    padding: 0;
                    display: block;
                    float: none;
                    width: 100%;
                    text-align: left;
                    box-sizing: content-box;
                    
                    a, button{
                        display: block;
                        text-transform: initial;
                        font-weight: 400;
                        color: #555D65;
                        font-size: 14px;
                        line-height: 26px;
                        
                        &:hover{
                            text-decoration: underline;
                        }
                    }

                    &.last {
                        border-top: 1px solid #CCD8E2;
                        width: 172px ;
                        position: relative;
                        right: 35px;
                        padding: 15px 20px 5px 70px;
                        margin-top: 17px;

                        &:before {
                            content: '';
                            display: inline-block;
                            background: url(${process.env.NEXT_PUBLIC_CDN_HOST}images.flyer.eu/2018/Home/iconessvg/logout.svg) no-repeat;
                            background-size: 86%;
                            width: 36px;
                            height: 36px;
                            margin: 0;
                            padding: 0;
                            position: absolute;
                            bottom: -4px;
                            left: 28px;
                        }

                        a{
                            border: none;
                            padding-right: 0;
                        }
                    }
                }
            }

            &:before {
                content: '';
                width: 15px;
                height: 10px;
                position: absolute;
                right: 125px;
                top: -10px;
                background: url(${process.env.NEXT_PUBLIC_CDN_IMAGES}home/triangle-login.png) no-repeat center center;
            }
        }

        &:hover{
            .profil-dropdown{
                display: block;
            }
        }
    }
`;

export const Link = styled.a`
    display: inline-block;
    vertical-align: middle;
    color: var(--dark);
    padding-left: 2em;
    position: relative;

    &:hover {
        color: var(--normal);
        text-decoration: none;
    }

    > div {
        display: table;
        table-layout: fixed;

        > em {
            display: table-cell;
            margin: 0;
            position: relative;

            > i {
                max-width: 32px;
                width: 100%;
                font-size: 2em;
                color: var(--dark);
                transition: all .2s;

                @media (max-width: 375px) {
                    max-width: 25px;
                    font-size: 1.55em;
                }
            }
        }

        > span {
            vertical-align: middle;
            font-size: ${em('13px')};
            font-weight: 600;
            padding: 3px 0 0 10px;
            ${ellipsis('120px')}
            display: table-cell;

            @media (max-width: 1085px) {
                font-size: ${em('12px')};
                padding: 3px 0 0 8px;
            }

            @media (max-width: 991px) {
                font-size: ${em('12px')};
            }

            @media (max-width: 767px) {
                display: none;
            }
        }

        > b {
            display: none;
            position: absolute;
            bottom: 6px;
            right: -5px;
            width: 13px;
            height: 13px;
            border-radius: 50em;
            background-color: var(--success);

            @media (max-width: 767px) {
                display: block;
            }
        }
    }

    &.__loading{
        opacity: 0.2;
        animation: profileAnimate 2s ease-in infinite;
        @keyframes profileAnimate { 
            0% { opacity: 0.2; }
            50% { opacity: 0.8; }
            100% { opacity: 0.2; }
        }
    }

    @media (max-width: 1060px) {
        padding-left: 1.35em;
    }

    @media (max-width: 375px) {
        padding-left: .7em;
    }
`;

export const Cart = styled.strong`
    background-color: #F07837;
    color: #fff;
    border-radius: 50em;
    ${size('21px', '21px')}
    font-style: normal;
    font-size: ${em('11px')};
    text-align: center;
    line-height: 21px;
    position: absolute;
    right: -10px;
    top: -7px;
    box-shadow: 0 2px 9px 0 ${rgba('#F07837', 0.5)};
`;

export const Mobile = styled.button`
    display: none;
    border: 0;
    background: var(--primary) linear-gradient(to right, var(--primary) 0%, var(--secondary) 100%);
    box-shadow: 0 0 10px 0 rgba(35,166,255,0.5), 0 0 8px 0 rgba(0,150,255,0.5);
    position: relative;
    border-radius: 50em;
    ${size('36px', '36px')}
    margin-left: 26px;
    cursor: pointer;
    float: right;
    right: 0;
    transition: right .4s;
    transition-timing-function: cubic-bezier(0.7,0,0.3,1);

    &:before {
        content: "";
        position: absolute;
        left: -12px;
        top: 4px;
        ${size('25px', '1px')}
        background-color: #e7e7e7;
        display: block;
    }

    &:focus {
        outline: 0;
    }

    ${StyledHamburger} {
        left: 50%;
        top: 50%;
        margin: 0;

        > span, &:before, &:after {
            background-color: #fff;
            transition: all .2s;
        }
    }

    &.__clicked  {
        position: fixed;
        z-index: 1002;
        transition: right 0.8s;
        transition-timing-function: cubic-bezier(0.7,0,0.3,1);
        right: 233px;
        margin-left: 0;
        transform: translateY(-50px);

        &:before {
            display: none;
        }

        ${StyledHamburger} {
            > span {
                transform: translate(50%,0);
                opacity: 0;
            }

            &:after {
                transform: rotateZ(45deg);
                top: -7px;
            }

            &:before {
                transform: rotateZ(-45deg);
                top: 7px;
            }
        }
    }

    @media (max-width: 991px) {
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

export const Info = styled.a`
    color: var(--light);
    font-size: 12px;
    line-height: 13px;
    padding: 8px 0 4px 39px;
    width: 185px;
    float: left;
    display: none;
    position: relative;
    top: -6px;
    left: 35px;
    background: url(${process.env.NEXT_PUBLIC_CDN_IMAGES}home/${process.env.NEXT_PUBLIC_SHOP_NAME === 'office' ? 'unbeatable_pricesof' : 'unbeatable_prices'}.png) no-repeat left center;
    pointer-events: none;
    text-align: left;

    @media (max-width: 1240px) {
        font-size: 11px;
        width: 150px;
        padding: 8px 0 4px 44px;
    }

    @media (max-width: 1110px) {
        left: 5px;
    }

    @media (max-width: 1000px) {
        font-size: 10px;
        padding: 8px 0 4px 39px;
        width: 130px;
    }

    @media (max-width: 991px) {
        font-size: 12px;
        padding: 8px 0 4px 40px;
        width: 190px;
    }

    @media (max-width: 920px) {
        font-size: 11px;
        width: 160px;
        display: none;
    }
`;
